import './App.css';
import Resume from './components/Resume'

function App() {
  return (
    <div className='App'>
      <Resume />
    </div>);
}

export default App;
